import { Box, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, useDisclosure, Button, Stack, Text, Divider, Heading } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useCreateCaseStore } from "../../store/CreateCaseStore";
import { SCHEDULE_MINUTES, SERVICE_TYPE_MAP } from "../../config/constant";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import KeyValueRenderV2 from "@components/atom/KeyValueV2";
import { createCaseDispatchTypeSelect } from "@config/event";

export default function DispatchType() {
  const localOrderChanges = useRedStore(state => state.localOrderChanges);

  return (
    <Box p={2} m={2} bg={"white"} shadow={"sm"} borderRadius={"md"}>
      <KeyValueRenderV2 title="When do you need?" subTitle="" rightSideComponent={<DispatchTypeOptions />} icon="uim:schedule" />
      {!!localOrderChanges?.scheduledDdtm && localOrderChanges.dispatchType === "SCHEDULED" && (
        <Flex mt={4} align={"center"} justify={"center"} bg={"purple.50"} py={2} borderWidth={1} borderRadius={"md"} shadow={"sm"}>
          <Icon icon={"svg-spinners:clock"} fontSize={"xs"} />
          <Text fontWeight={"normal"} fontSize={"xs"} ml={2}>
            Scheduled at:{" "}
          </Text>
          <Text fontWeight={"semibold"} fontSize={"xs"} ml={2}>
            {dayjs.unix(localOrderChanges?.scheduledDdtm / 1000).format("DD MMMM YYYY hh:mm A")}
          </Text>
        </Flex>
      )}
    </Box>
  );
}

const DispatchTypeOptions = () => {
  const order = useRedStore(state => state.order);
  const localOrderChanges:any = useRedStore(state => state.localOrderChanges);
  const { __updateDispatchType, __updateScheduledDdtm } = useOrderAction();

  const serviceType = localOrderChanges?.serviceType;
  const selectedDateTime = localOrderChanges?.scheduledDdtm || order.scheduledDdtm || new Date();
  const isScheduleOnly = SERVICE_TYPE_MAP[serviceType]?.effect?.dispatchType === "SCHEDULED";

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isScheduleOnly && !localOrderChanges.scheduledDdtm) {
      onOpen();
    }
  }, [localOrderChanges.scheduledDdtm, isScheduleOnly, onOpen]);

  const isDisable = dayjs(selectedDateTime).isBefore(dayjs().add(SCHEDULE_MINUTES - 1, "minutes"));

  const onSwitch = (value: boolean) => {
    if (value) {
      __updateDispatchType("SCHEDULED");
      createCaseDispatchTypeSelect({ orderId: order.orderId, dispatchType: "SCHEDULED" });
      onOpen();
    } else {
      __updateDispatchType("NOW");
      createCaseDispatchTypeSelect({ orderId: order.orderId, dispatchType: "NOW" });
      onClose();
    }
  };

  const userDateSelect = (date: Date) => {
    __updateScheduledDdtm(date);
  };

  const dispatchType = localOrderChanges?.dispatchType;
  const remove = useRedStore(state => state._removeAddons);
  const selectedAddons:any = useRedStore(state => state.selectedAddons);

  function handleDispatchNow(){
    onSwitch(false);
    selectedAddons.map((selectedAddon) => remove(selectedAddon))
  }

  return (
    <Box>
      <Flex w={20} align={"flex-end"} direction={"column"}>
        <Flex bg={"primary.50"} px={1} py={1} borderRadius={"full"} fontSize={"xs"} justify={"space-between"} align={"center"} gap={0} fontWeight={"semibold"} borderWidth={1}>
          {!isScheduleOnly && (
            <Box
              px={3}
              borderRadius={"full"}
              py={0.5}
              bg={dispatchType === "NOW" ? "primary.200" : "transparent"}
              color={"primary.900"}
              onClick={() => handleDispatchNow()}
              cursor={"pointer"}
              fontSize={"sm"}
            >
              now
            </Box>
          )}
          <Box
            fontSize={"sm"}
            px={3}
            borderRadius={"full"}
            py={0.5}
            bg={dispatchType === "SCHEDULED" ? "primary.200" : "transparent"}
            color={"primary.900"}
            onClick={() => onSwitch(true)}
            cursor={"pointer"}
          >
            Scheduled
          </Box>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent mx={2} bg={"white"}>
          <ModalHeader mb={-2} fontSize={"md"}>
            Schedule Dispatch
          </ModalHeader>
          <Divider />
          <ModalBody>
            <Heading fontWeight={"semibold"} fontSize={"xs"}>
              When do you want the patient to be picked up?
            </Heading>
            <Box>
              <DatePickerHandler userDateSelect={userDateSelect} />
            </Box>
            <Stack gap={0}>
              <Text fontSize={"2xs"}>* Choose exact pick-up time up to 30 days</Text>
              <Text fontSize={"2xs"}>* Extra time included to meet your trip</Text>
              <Text fontSize={"2xs"}>* Cancel at no charge up to 60 mins in</Text>
              advance
            </Stack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button variant={"outline"} mr={3} fontWeight={"semibold"} fontSize={"xs"} size={"sm"} rounded={"full"} px={4} onClick={onClose}>
              Close
            </Button>
            <Button variant="solid" fontWeight={"semibold"} fontSize={"xs"} size={"sm"} rounded={"full"} px={8} opacity={isDisable ? 0.4 : 1} disabled={isDisable} onClick={onClose}>
              Schedule
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

// const numberCell = () => {
//   const ddd = dayjs().add(46, "minutes").toDate().getMinutes();
//   const nhm = Math.ceil(ddd / 2) * 2;
//   return nhm;
// };

export const DatePickerHandler = ({ userDateSelect }: any) => {
  const minDate = new Date();

  const [startDate, setStartDate] = useState(dayjs().add(SCHEDULE_MINUTES, "minutes").toDate());
  const setSelectedDateTime = useCreateCaseStore(state => state.setSelectedDateTime);

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return (
      dayjs(currentDate)
        .add(SCHEDULE_MINUTES - 1, "minutes")
        .toDate()
        .getTime() < selectedDate.getTime()
    );
  };

  const selectedDdtm = (dte: any) => {
    setStartDate(dte);
    userDateSelect(dte);
    setSelectedDateTime(dayjs(dte).toDate().getTime());
  };

  return (
    <Box width={"full"} py={4}>
      <DatePicker selected={startDate} onChange={selectedDdtm} showTimeSelect dateFormat="MMMM d, yyyy h:mm aa" minDate={minDate} filterTime={filterPassedTime} timeIntervals={1} />
    </Box>
  );
};
