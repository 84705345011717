import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Wrap,
  WrapItem,
  useDisclosure,
  Stack,
  Flex,
  Heading,
  Text,
  Card,
  Badge,
  Tag,
  TagLabel,
  TagRightIcon,
  DrawerCloseButton,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  InputRightAddon,
  FormLabel,
  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
import { ADDON_ICONS } from "../../config/constant";
import { Icon } from "@iconify/react";
import { IAddons } from "../../config/types";
import KeyValueRender from "../atom/KeyValueRender";
import { convertPrice } from "../../utils";
import { useRedStore } from "@store/store";
import { useEffect, useState } from "react";
import { createCaseAddonButtonClick, createCaseCloseAddons, createCaseSubmitAddons } from "@config/event";

export default function AddonSection() {
  const order:any = useRedStore(state => state.order);
  const localOrderChanges:any = useRedStore(state => state.localOrderChanges);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectedAddons:any = useRedStore(state => state.selectedAddons);
  const _setSelectedAddons = useRedStore(state => state._setSelectedAddons);
  const addons = useRedStore(state => state.addons);

  const add = useRedStore(state => state._addAddons);
  const remove = useRedStore(state => state._removeAddons);
  const changePrice = useRedStore(state => state._changeAddonPrice);

  const onSubmit = () => {
    createCaseSubmitAddons({ orderId: order?.orderId, addons: JSON.stringify(selectedAddons) });
    handleClose();
  };

  const addonPrice = convertPrice(selectedAddons?.filter(itm => !!itm.quantity).reduce((a, b) => a + b.price, 0) || 0);

  const subTypeAddonError = selectedAddons?.find((addon: any) => addon.isCustomPrice && addon?.subtypes?.[0]?.id && !addon?.type?.split(" - ")?.[1]);
  const customPriceAddonError = selectedAddons?.find((addon: any) => addon.isCustomPrice && (addon.price === null || addon.price === undefined || addon.price === ""));

  const addonCount = selectedAddons?.filter(itm => !!itm.quantity).length || 0;
  const [name, setName] = useState("");
  const defaultAddon:any = addons?.find((addon) => addon.id == "1008_MET")

  useEffect(() => {
    if(selectedAddons?.[0] || localOrderChanges?.entityRequired?.[0]?.subtype?.substring(0, 3).toLowerCase() != "als" || !["STANDARD"].includes(order?.serviceType) || localOrderChanges?.billToClient || localOrderChanges?.dispatchType == "NOW") return 
      add({ ...defaultAddon, isCustomPrice: "", quantity: 1 })
      _setSelectedAddons([{ ...defaultAddon, isCustomPrice: "", quantity: 1 }]);
  },[localOrderChanges])

  const handleClose = () => {
    onClose();
    setName("");
    createCaseCloseAddons({ orderId: order?.orderId });
  };

  const handleOpen = () => {
    createCaseAddonButtonClick({ orderId: order?.orderId });
    onOpen();
  };

  return addons && addons?.length > 0 ? (
    <Box px={4} py={2} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"}>
      <Box>
        <KeyValueRender
          title="Addons"
          subTitle=""
          rightSideComponent={
            <Tag rounded={"full"} size={"md"} variant="solid" bg="purple.600" fontWeight={"semibold"} textTransform={"capitalize"} px={4} py={1} pr={2} gap={2} cursor={"pointer"} onClick={handleOpen}>
              <TagLabel fontSize={"sm"} textTransform={"uppercase"} mt={0.5}>
                Add
              </TagLabel>
              <TagRightIcon as={AddIcon} fontSize={24} />
            </Tag>
          }
          icon="bi:plugin"
        />
        {selectedAddons?.filter(itm => !!itm.quantity)?.length > 0 && (
          <Box>
            <Divider />
            <Box mt={2} pos={"relative"}>
              <Stack bg={"purple.100"} gap={1} px={4} py={2} borderRadius={"md"}>
                {selectedAddons && selectedAddons?.map((itm: IAddons) => <AddonBadge itm={itm} />)}
              </Stack>{" "}
            </Box>
            <Divider />

            <Box mt={4}>
              <Flex gap={2} my={2} direction={"column"}>
                {subTypeAddonError && (
                  <Flex justify={"space-between"}>
                    <Text color="red" fontSize="xs">
                      Please check addons {[subTypeAddonError && "sub-type"].filter(Boolean)}
                    </Text>
                  </Flex>
                )}
                {customPriceAddonError && !order?.isOverridenPrice && (
                  <Flex justify={"space-between"}>
                    <Text color="red" fontSize="xs">
                      Please check addons, Enter Custom Price.
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Flex justify={"space-between"}>
                <Text fontWeight={"semibold"} fontSize={"sm"}>
                  Addons: {addonCount}
                </Text>
                {!order?.isOverridenPrice && (
                  <Flex align={"center"}>
                    <Text fontWeight={"normal"} fontSize={"sm"}>
                      Addons amount:
                    </Text>
                    <Text fontWeight={"bold"} fontSize={"sm"}>
                      {" "}
                      ₹{addonPrice}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>
          </Box>
        )}
      </Box>
      <Drawer placement={"bottom"} onClose={handleClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg={"gray.50"}>
          <DrawerHeader borderBottomWidth="1px" fontSize={"sm"}>
            Addons
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody minH={"100vh"}>
            <FormControl mb={5}>
              <FormLabel fontWeight={"semibold"}>Search Addons</FormLabel>
              <Input type="text" placeholder={"Addons Name ... "} onChange={(e: any) => setName(e.target.value)} />
              <FormHelperText fontSize={"xs"}>EX: Helper, Oxygen, Doctor, Nurses </FormHelperText>
            </FormControl>
            <Stack mx={-2} pb={20}>
              {addons
                ?.filter(itm => itm?.name?.toLowerCase()?.includes(name?.toString()?.toLowerCase()))
                .map((itm: IAddons) => (
                  <AddonRender
                    isOverridenPrice={order?.isOverridenPrice}
                    key={`${itm.id}_${itm.key}`}
                    addon={itm}
                    add={add}
                    selectedAddons={selectedAddons}
                    remove={remove}
                    changePrice={changePrice}
                    quantity={selectedAddons?.find(item => item.key === itm.key)?.quantity || 0}
                  />
                ))}
              <Flex justify={"space-between"} align={"center"} pos={"fixed"} bottom={0} bg={"white"} w={"full"} left={0} px={4} py={2} shadow={"2xl"}>
                <Box>
                  <Text fontWeight={"semibold"} fontSize={"md"}>
                    Addons: {addonCount}
                  </Text>
                  <Text fontWeight={"normal"} fontSize={"sm"} opacity={0.7}>
                    Addons amount: <span style={{ fontWeight: 800, color: "#000000" }}> ₹{addonPrice}</span>
                  </Text>
                </Box>
                <Button size={"sm"} px={5} borderRadius={"full"} fontWeight={"semibold"} onClick={onSubmit}>
                  View Case
                </Button>
              </Flex>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  ) : (
    <></>
  );
}

const AddonRender = ({
  addon,
  add,
  remove,
  changePrice,
  quantity,
  selectedAddons,
  isOverridenPrice,
}: {
  addon: IAddons;
  add: any;
  remove: any;
  changePrice: any;
  quantity: number;
  selectedAddons: any;
  isOverridenPrice: boolean;
}) => {
  const { key, name } = addon;
  const icon = ADDON_ICONS[key || name];
  const selectedAddon: any = selectedAddons.find((x: any) => {
    return x.id === addon.id;
  });

  const [subtypeValue, setSubtypeValue] = useState<any>(selectedAddon?.type ? selectedAddon?.type.split(" - ")[1] : "");
  const [customPrice, setCustomPrice] = useState<any>(selectedAddon?.price || selectedAddon?.price === 0 ? selectedAddon?.price : "");

  return (
    <Card shadow={"sm"} my={2} p={2} borderRadius={"md"} _hover={{ shadow: "md" }} colorScheme="orange" cursor={"pointer"} aria-selected="true" variant={"filled"} w={"full"}>
      <Flex justify={"space-between"} align={"start"} w={"full"}>
        <Flex justify={"space-between"} align={"start"} w={"full"}>
          <Flex minH={8} minW={8} maxH={8} bg={"purple.100"} justify={"center"} align={"center"} borderRadius={"full"} color={"purple.800"}>
            <Icon icon={icon} fontSize={18} />
          </Flex>
          <Stack ml={2} justify={"center"} w={"full"}>
            <Flex justify={"space-between"} w={"full"}>
              <Heading mb={-2} fontWeight={"semibold"} fontSize={"sm"} textTransform={"capitalize"}>
                {selectedAddon && selectedAddon?.type ? selectedAddon?.type?.toString().toLowerCase() : name?.toString().toLowerCase()}
              </Heading>
              <WrapItem>
                <Badge colorScheme="red" fontSize={"sm"}>
                  {addon.isCustomPrice ? "" : `₹ ${convertPrice(addon.price)} `}
                </Badge>
              </WrapItem>
            </Flex>
            <Wrap my={1}>
              <WrapItem gap="2">
                <Badge colorScheme="blackAlpha" fontSize={"sm"} textTransform={"capitalize"} fontWeight={"normal"}>
                  Max: {addon.maxqty}
                </Badge>
                <Badge colorScheme="blackAlpha" textTransform={"capitalize"} fontWeight={"normal"} fontSize={"sm"}>
                  {addon.isCustomPrice ? "Custom Price" : ""}
                </Badge>
              </WrapItem>
              {addon.subtypes && quantity > 0 && (
                <Select
                  placeholder="Select sub-type"
                  size={"sm"}
                  value={subtypeValue}
                  onChange={(e: any) => {
                    setSubtypeValue(e.target.value);
                    changePrice({ ...addon, type: e.target.value ? `${addon.name} - ${e.target.value}` : addon.name, price: customPrice ? customPrice * 100 : null });
                  }}
                >
                  {addon.subtypes.map((opt: any) => (
                    <option key={opt.key} value={opt.name}>
                      {opt.name}
                    </option>
                  ))}
                </Select>
              )}
              {quantity > 0 && addon.isCustomPrice && !isOverridenPrice && (
                <Flex alignItems={"center"} gap={"2"}>
                  Price:
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" color="gray.300" h="full" fontSize="0.8em">
                      ₹
                    </InputLeftElement>
                    <Input
                      size={"sm"}
                      prefix="₹"
                      pl="8"
                      placeholder="Enter Custom Price"
                      value={customPrice || customPrice === 0 ? `${customPrice / 100}` : undefined}
                      onChange={(e: any) => {
                        const val = e.target.value;
                        if (val && !/^\d+$/.test(val)) return;
                        const value = val ? parseInt(val) * 100 : "";
                        setCustomPrice(value);
                        // if ((!value && value !== 0) || value < 0) {
                        //   changePrice({ ...addon, type: addon.subtypes?.[0] ? `${addon.name} - ${subtypeValue}` : addon.name, quantity, price: "" });
                        //   return;
                        // }
                        changePrice({ ...addon, type: addon.subtypes?.[0] ? `${addon.name} - ${subtypeValue}` : addon.name, quantity, price: value });
                      }}
                    />
                  </InputGroup>
                </Flex>
              )}
            </Wrap>
          </Stack>
        </Flex>
        <Box ml={5}>
          <Flex justify={"space-between"} align={"center"} gap={2}>
            <Button
              onClick={() => {
                remove(addon);
                if (addon.isCustomPrice && quantity == 1) {
                  setCustomPrice(0);
                  setSubtypeValue("");
                }
              }}
              size={"sm"}
              variant={"outline"}
              borderColor={"purple.200"}
            >
              -
            </Button>
            <Text fontSize={"md"} fontWeight={"bold"}>
              {quantity}
            </Text>
            <Button
              onClick={() => {
                add(addon);
              }}
              size={"sm"}
              variant={"outline"}
              borderColor={"purple.200"}
            >
              +
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
};

const AddIcon = () => <Icon icon={"icon-park-twotone:add-one"} />;

const AddonBadge = ({ itm }: { itm: IAddons }) => {
  const title = itm && itm?.type ? itm?.type?.toString().toLowerCase() : itm?.name?.toString().toLowerCase();

  const continues = (sentence: string, wordLimit: number) => {
    if (title?.length > wordLimit + 3) {
      return sentence.slice(0, wordLimit).concat("...");
    }
    return sentence;
  };
  return (
    // <Badge
    //   colorScheme="orange"
    //   whiteSpace={"normal"}
    //   px={2}
    //   py={0.5}
    //   textTransform={"capitalize"}
    // >
    <Text fontSize={"xs"} fontWeight={"semibold"} textTransform={"capitalize"} color={"purple.700"}>
      {continues(title, 30)} x {itm.quantity}
    </Text>
    // </Badge>
  );
};
