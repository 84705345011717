import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Flex,
  Divider,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Textarea,
  FormHelperText,
  FormControl,
} from "@chakra-ui/react";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { CheckIcon } from "@chakra-ui/icons";
import DropDownSection from "../atom/DropDownSection";
import { BILL_TO_OPTIONS, OVERRIDE_COMMENTS } from "../../config/constant";
import { convertPrice, convertTo } from "../../utils";
import CustomInputSection from "../atom/CustomInputSection";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useRedStore } from "@store/store";
import CustomCommentSection from "@components/atom/CustomCommnetSection";
import { useSatthiPricing } from "@hooks/api/useCaseApi";
import { debounce } from "lodash";
import RedMargin from "@components/molecules/RedMargin";
import { createCaseNegotiatePriceClick, createCaseNegotiatePriceSubmit, createCasePricingMode } from "@config/event";
import { useParams } from "react-router-dom";

export default function PriceSection() {
  // const currentCase = useCreateCaseStore(state => state.changes);
  // const setCaseProps = useCreateCaseStore(state => state.setCaseProps);

  const order = useRedStore(state => state.order);

  const localOrderChanges = useRedStore(state => state.localOrderChanges);
  const selectedAddons = useRedStore(state => state.selectedAddons);
  const pricing = useRedStore(state => state.price);

  const { __updateCaseOverride } = useOrderAction();

  const selectedTabIndex = localOrderChanges?.isOverridenPrice ? 1 : 0;
  const addonPrice = selectedAddons?.filter(itm => !!itm.quantity).reduce((a, b) => a + b.price, 0) || 0;

  return (
    <Box p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"}>
      <Tabs
        onChange={(index: number) => {
          createCasePricingMode({ mode: index === 0 ? "CONTRACT" : "OVERRIDE", orderId: order?.orderId });
          __updateCaseOverride(!!index);
        }}
        variant="soft-rounded"
        colorScheme="green"
        size={"sm"}
        defaultIndex={selectedTabIndex || 0}
      >
        <TabList w={"full"} display={"flex"} py={1} px={1} borderRadius={"full"}>
          <Tab
            isDisabled={order?.isOverridenPrice}
            flex={1}
            fontSize={"sm"}
            _selected={{
              color: "white",
              bg: "purple.600",
              borderRadius: "full",
            }}
          >
            Contract
          </Tab>
          <Tab
            flex={1}
            fontSize={"sm"}
            _selected={{
              color: "white",
              bg: "purple.600",
              borderRadius: "full",
            }}
          >
            Override
          </Tab>
        </TabList>
        <TabPanels borderWidth={0}>
          <TabPanel p={0} borderWidth={0}>
            {!!pricing.minMaxPrice && !!localOrderChanges.fareAgreed && (
              <Contract price={localOrderChanges.fareAgreed} minMaxPrice={pricing.minMaxPrice} isBillToClient={localOrderChanges.billToClient} addonPrice={addonPrice} />
            )}
          </TabPanel>
          <TabPanel p={0} borderWidth={0}>
            <Overridden isDisabled={order?.isOverridenPrice} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

const Overridden = ({ isDisabled = false }: { isDisabled: boolean }) => {
  const currentCase = useRedStore(state => state.localOrderChanges);
  const order = useRedStore(state => state.order);
  const pricing = useRedStore(state => state.price);

  const localOrderChanges:any = useRedStore(state => state.localOrderChanges);
  const addons = useRedStore(state => state.addons);
  const defaultAddon:any = addons?.find((addon) => addon.id == "1008_MET")
  const [defaultAddonPrice, setDefaultAddonPrice] = useState(0)
  
  const selectedAddons:any = useRedStore(state => state.selectedAddons);
  const addonPrice = selectedAddons?.filter(itm => !!itm.quantity).reduce((a, b) => a + b.price, 0) || 0;
  const totalPrice = (pricing?.price || 0) + defaultAddonPrice + addonPrice
  useEffect(() => {
    console.log(localOrderChanges?.entityRequired?.[0]?.subtype?.substring(0, 3).toLowerCase())
    if (localOrderChanges?.entityRequired?.[0]?.subtype?.substring(0, 3).toLowerCase() == "als" && ["STANDARD"].includes(localOrderChanges?.serviceType) && !localOrderChanges?.billToClient && localOrderChanges?.dispatchType == "NOW"){
      setDefaultAddonPrice(() => defaultAddon?.price)
    }
    else{
      setDefaultAddonPrice(0)
    }

  },[localOrderChanges])

  const { __updateOverridePriceTo, __updateOverrideBillTo, __updateOverrideComments } = useOrderAction();

  const onPriceChange = (e: any) => {
    __updateOverridePriceTo(parseInt(e.target.value));
  };

  const onBillToChange = (e: any) => {
    __updateOverrideBillTo(e?.value);
  };

  const onTextAreaChanges = (e: any) => {
    __updateOverrideComments(e.target.value);
  };

  const showRecommendedPrice = pricing?.price && (!pricing?.billToClient || currentCase?.overwriteBillTo !== "BTC");

  return (
    <Box mt={4}>
      <Text fontWeight={"normal"} fontSize={"sm"} mb={4}>
        This is the override mode. In this mode, you can choose who will receive the bill - either the patient or the client. You have full control over the fee amount. Now, you can set any fee that
        is ₹1 or higher.
      </Text>
      <Text fontWeight={"semibold"} fontSize={"sm"} mb={4} color={"red"}>
        🔴 Overridden price is inclusive of add-ons pricing
      </Text>
      <Box mx={-2}>
        <DropDownSection
          // defaultValue={pricing?.billToClient ? "BTC" : "BTP"}
          // !currentCase?.billToClient && !order?.isOverridenPrice ? "BTP" :
          value={order?.overridenType ? (order?.overridenType === "BILL_TO_PATIENT" ? "BTP" : "BTC") : ""}
          icon="ic:baseline-window"
          title="Bill To"
          options={BILL_TO_OPTIONS?.filter(itm => (currentCase?.billToClient || order?.overwriteBillTo === "BTC" || order?.isOverridenPrice ? true : itm.value === "BTP"))}
          onSelect={onBillToChange}
          maxWidth={180}
          minWidth={180}
          isDisabled={isDisabled}
          isRequired
        />
        <CustomCommentSection
          isDisabled={isDisabled}
          defaultValue={currentCase?.overridenComments}
          // value={convertPrice(currentCase?.overwriteFare || 0)}
          icon="basil:comment-solid"
          title="Comments"
          onSelect={onTextAreaChanges}
          maxWidth={180}
          minWidth={180}
          isRequired
        />
        {/* <OverrideComments isDisabled={isDisabled} /> */}
        <CustomInputSection
          isDisabled={isDisabled}
          defaultValue={convertPrice(currentCase?.overwriteFare || 0)}
          // value={convertPrice(currentCase?.overwriteFare || 0)}
          icon="fa-solid:rupee-sign"
          title="Custom Price"
          onSelect={onPriceChange}
          maxWidth={180}
          minWidth={180}
          isRequired
        />
        {showRecommendedPrice && (
          <Box mr={2}>
            <Text textAlign={"right"} fontSize={"sm"} fontWeight={"bold"} color={"gray.600"}>
              Recommended Fare: <span style={{ color: "red" }}> ₹{convertPrice(totalPrice)}</span>
            </Text>
          </Box>
        )}
        {!!currentCase?.overwriteFare &&
          <Box mr={2}>
          <Text textAlign={"right"} fontSize={"sm"} fontWeight={"bold"} color={"gray.600"}>
            Discount Amount: <span style={{ color: "red" }}> ₹{convertPrice(totalPrice)-(currentCase?.overwriteFare || 0)}{"(" + ((Math.round((((totalPrice)/100 - (currentCase?.overwriteFare || 0))/(totalPrice))*1000000))/100)+'%)'}</span>
          </Text>
        </Box>
        }
      </Box>
    </Box>
  );
};

const OverrideCommentsFreeField = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const currentCase = useRedStore(state => state.localOrderChanges);
  const { __updateOverrideComments } = useOrderAction();

  const [isOtherVisible, setIsOtherVisible] = useState(false);

  const onSelect = (e: any) => {
    setIsOtherVisible(e.value === "other");
    __updateOverrideComments(e.value);
  };

  const onTextAreaChanges = (e: any) => {
    __updateOverrideComments(e.target.value);
  };

  return (
    <Box>
      {isOtherVisible && (
        <Flex direction={"column"} align={"end"}>
          <Textarea
            onChange={onTextAreaChanges}
            w={"180px"}
            placeholder="Here is a sample placeholder"
            mr={2}
            fontSize={"sm"}
            p={2}
            defaultValue={currentCase?.overridenComments}
            isDisabled={isDisabled}
          />
        </Flex>
      )}
    </Box>
  );
};

const OverrideComments = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const currentCase = useRedStore(state => state.localOrderChanges);
  const { __updateOverrideComments } = useOrderAction();

  const [isOtherVisible, setIsOtherVisible] = useState(false);

  const onSelect = (e: any) => {
    setIsOtherVisible(e.value === "other");
    __updateOverrideComments(e.value);
  };

  const onTextAreaChanges = (e: any) => {
    __updateOverrideComments(e.target.value);
  };

  return (
    <Box>
      <DropDownSection
        defaultValue={currentCase?.overridenComments}
        value={currentCase.overridenComments}
        icon="basil:comment-solid"
        title="Comments"
        options={OVERRIDE_COMMENTS}
        onSelect={onSelect}
        maxWidth={180}
        minWidth={180}
        isDisabled={isDisabled}
      />

      {isOtherVisible && (
        <Flex direction={"column"} align={"end"}>
          <Textarea
            onChange={onTextAreaChanges}
            w={"180px"}
            placeholder="Here is a sample placeholder"
            mr={2}
            fontSize={"sm"}
            p={2}
            defaultValue={currentCase?.overridenComments}
            isDisabled={isDisabled}
          />
        </Flex>
      )}
    </Box>
  );
};

interface IContract {
  price: number;
  minMaxPrice: { min: number | null; max: number | null };
  isBillToClient: boolean;
  addonPrice: number;
}
const Contract = ({ price = 0, minMaxPrice = { min: 0, max: 0 }, isBillToClient = false, addonPrice = 0 }: IContract) => {
  const { id } = useParams();
  const localOrderChanges:any = useRedStore(state => state.localOrderChanges);
  const submitFnRef: any = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { __updateFearAgreed } = useOrderAction();
  const [enteredValue, setEnteredValue] = useState(convertPrice(price));
  const [distance, city, selectedAddons, constToOwner] = useRedStore(state => [state.price.distance, state.localOrderChanges.city, state.selectedAddons, state.constToOwner]);
  const { execute, ...reset } = useSatthiPricing();
  const addons = useRedStore(state => state.addons);
  const defaultAddon:any = addons?.find((addon) => addon.id == "1008_MET")
  const [defaultAddonPrice, setDefaultAddonPrice] = useState(0)
  const [nightPrice, setNightPrice] = useState(0)

  const isNightTime = (timestamp?: number) => {
    const date = timestamp ? new Date(timestamp) : new Date();
    const hours = date.getHours();
  
    return hours >= 22 || hours < 6;
  };
  
  useEffect(() => {
    if((localOrderChanges?.dispatchType === "SCHEDULED" && localOrderChanges?.scheduledDdtm && isNightTime(localOrderChanges?.scheduledDdtm)) || (localOrderChanges?.dispatchType === "NOW" && isNightTime()) && !localOrderChanges?.isBillToClient)
      setNightPrice(price*0.2)
    else 
      setNightPrice(0)
  },[localOrderChanges?.dispatchType, localOrderChanges?.scheduledDdtm, price])

  useEffect(() => {
    if (localOrderChanges?.entityRequired?.[0]?.subtype?.substring(0, 3).toLowerCase() == "als" && ["STANDARD"].includes(localOrderChanges?.serviceType) && !localOrderChanges?.billToClient && localOrderChanges?.dispatchType == "NOW"){
      setDefaultAddonPrice(() => defaultAddon?.price)
    }
    else{
      setDefaultAddonPrice(0)
    }
    
  },[localOrderChanges])

  const onNegotiateHandler = (val: any) => {
    __updateFearAgreed(convertTo(val));
    onClose();
  };

  const onSubmitClick = () => {
    if (submitFnRef.current) {
      submitFnRef?.current?.onSubmitCb();
    }
  };

  const db = debounce(execute, 400);

  // this depends on
  useEffect(() => {
    db();
  }, [distance, city, selectedAddons]);

  const handleOpen = () => {
    onOpen();
    id && createCaseNegotiatePriceClick({ orderId: id });
  };

  return (
    <Box mt={4}>
      <Text fontWeight={"normal"} fontSize={"sm"} mb={4}>
        This is the contract mode. In this mode, the price and bill will be determined by the hospital contract. The negotiate amount should be between the minimum and maximum fare.
      </Text>
      {isBillToClient ? (
        <Box>
          <Flex align={"center"} gap={2}>
            <Icon icon={"flat-color-icons:ok"} fontSize={20} />
            <Text fontSize={"sm"} fontWeight={"semibold"} mt={1}>
              {" "}
              Bill to Client
            </Text>
          </Flex>
        </Box>
      ) : (
        <>
          <Flex justify={"space-between"} align={"flex-start"} my={4}>
            <Flex>
              <Icon icon="streamline-emojis:ambulance" fontSize={16} />
              <Text fontSize={"sm"} fontWeight={"semibold"} ml={2} textDecoration={"InactiveCaptionText"}>
                Ambulance Fare
              </Text>
            </Flex>

            <Flex justify={"end"} align={"end"} direction={"column"}>
              <Text fontWeight={"semibold"} fontSize={"md"}>
                ₹{convertPrice(price + nightPrice)}
              </Text>
              {!!nightPrice  && 
              <Text fontSize={"xs"} fontWeight={"semibold"} ml={2} color={'purple.500'} textDecoration={"InactiveCaptionText"}>
                Including Night Surge
              </Text>
              }
              <Link fontSize={"xs"} textDecoration={"underline"} onClick={handleOpen}>
                Negotiate fare
              </Link>
            </Flex>
          </Flex>
          {(localOrderChanges?.entityRequired?.[0]?.subtype?.substring(0, 3).toLowerCase() == "als" && ["STANDARD"].includes(localOrderChanges?.serviceType) && !localOrderChanges?.billToClient && localOrderChanges?.dispatchType == "NOW") && 
            <Flex justify={"space-between"} align={"flex-start"} my={4}>
              <Flex>
                <Icon icon="streamline-emojis:ambulance" fontSize={16} />
                <Text fontSize={"sm"} fontWeight={"semibold"} ml={2} textDecoration={"InactiveCaptionText"}>
                  EMT Fare
                </Text>
              </Flex>
              

              <Flex justify={"end"} align={"end"} direction={"column"}>
                <Text fontWeight={"semibold"} fontSize={"md"}>
                  ₹{convertPrice(defaultAddonPrice)}
                </Text>
              </Flex>
              
            </Flex>
          }
          <Divider />
          
          <Flex justify={"space-between"} align={"center"} borderWidth={1} borderColor={"purple.300"} p={2} borderRadius={"md"} bg={"purple.50"}>
            <Flex justify={"center"} align={"center"}>
              <Icon icon="majesticons:money-line" fontSize={18} />
              <Text fontWeight={"semibold"} fontSize={"sm"} ml={2} mt={0.5}>
                Total
              </Text>
            </Flex>
            <Text fontWeight={"bold"} fontSize={"md"}>
              ₹ {convertPrice(price + nightPrice) + convertPrice(addonPrice + defaultAddonPrice)}
            </Text>
          </Flex>
          {(localOrderChanges?.entityRequired?.[0]?.subtype?.substring(0, 3).toLowerCase() == "als" && ["STANDARD"].includes(localOrderChanges?.serviceType) && !localOrderChanges?.billToClient && localOrderChanges?.dispatchType == "NOW") && 
            <Text color={'red'} fontSize={"xs"} ml={2} mt={1.5}>
              This price is inclusive of EMT
            </Text>
          }
          {price && <RedMargin price={price + nightPrice} addonPrice={addonPrice + defaultAddonPrice} />}
        </>
      )}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalHeader fontSize={"sm"} mb={-2}>
            Negotiate Ambulance Fare
          </ModalHeader>
          <Divider />

          <ModalBody>
            <NegotiateComponent
              ref={submitFnRef}
              ambFare={convertPrice(price + nightPrice)}
              maxFare={convertPrice(minMaxPrice.max)}
              minFare={convertPrice(minMaxPrice.min)}
              onSubmit={onNegotiateHandler}
              onChange={(e: any) => setEnteredValue(e)}
            />
          </ModalBody>

          <Divider />
          <ModalFooter mt={-2}>
            <Button variant={"outline"} mr={3} fontWeight={"semibold"} fontSize={"xs"} size={"sm"} rounded={"full"} px={4} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="solid"
              fontWeight={"semibold"}
              fontSize={"xs"}
              size={"sm"}
              rounded={"full"}
              px={8}
              bg="blue.500"
              onClick={onSubmitClick}
              colorScheme="blue"
              isDisabled={!enteredValue || enteredValue < convertPrice(minMaxPrice.min) || enteredValue > convertPrice(minMaxPrice.max)}
            >
              Agree Fare
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

interface INegotiateComponent {
  ambFare: number;
  maxFare: number;
  minFare: number;
  onSubmit: (val: any) => void;
  showMaxMin?: boolean;
  onChange?: (val: any) => void;
}

const NegotiateComponent = forwardRef((props: INegotiateComponent, ref: any) => {
  const { ambFare, maxFare, minFare, onSubmit, showMaxMin } = props;
  const { id } = useParams();
  const [negotiateAmount, setNegotiateAmount] = useState(ambFare);

  useImperativeHandle(ref, () => ({
    onSubmitCb() {
      const amount = isValidAmount ? negotiateAmount : ambFare;
      id && createCaseNegotiatePriceSubmit({ amount, orderId: id });
      onSubmit(amount);
    },
  }));

  const isValidAmount = negotiateAmount <= maxFare && negotiateAmount >= minFare;

  const setAmount = (e: any) => {
    setNegotiateAmount(parseInt(e.target.value));
    props.onChange && props.onChange(parseInt(e.target.value));
  };

  return (
    <Box>
      <Flex justify={"space-between"} align={"center"} mb={4} mt={2}>
        <Text fontWeight={"normal"} fontSize={"xs"}>
          Current Ambulance Fare
        </Text>
        <Text fontWeight={"bold"} fontSize={"sm"} color={"blackAlpha.900"}>
          ₹{ambFare}
        </Text>
      </Flex>
      <Flex my={5} justify={"space-between"} align={"start"}>
        <Text fontSize={"sm"} fontWeight={"bold"} minW={32} mt={3}>
          Agreed Fare
        </Text>
        <FormControl size={"sm"}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" color="gray.300" fontSize="14" children="₹" />
            <Input type="number" value={negotiateAmount} onChange={setAmount} placeholder="Enter amount" />
            <InputRightElement>{isValidAmount && <CheckIcon color="green.500" />}</InputRightElement>
          </InputGroup>
          <FormHelperText>{!isValidAmount && "❗ Fare entered is not allowed."}</FormHelperText>
        </FormControl>
      </Flex>
      {/* <Text fontWeight={"normal"} my={2} mb={4} fontSize={"xs"} fontStyle={"italic"}>
        <span style={{ fontWeight: 800, fontStyle: "normal" }}>Note:</span> The negotiated fare must be the same as or greater than the minimum amount, or it must be equal to or less than the maximum
        amount.
      </Text> */}
      {showMaxMin && (
        <Flex align={"center"} justify={"space-between"} px={2} py={1} borderRadius={"sm"} bg={"gray.200"} fontSize={"xs"} fontWeight={"semibold"}>
          <Flex justify={"center"} align={"center"}>
            <Text mr={2}>Min:</Text>
            <Text color="red.500" fontWeight={"bold"}>
              ₹{minFare}
            </Text>
          </Flex>
          <Icon icon={"ph:arrow-right-duotone"} />
          <Flex justify={"center"} align={"center"}>
            <Text mr={2}>Max:</Text>
            <Text color="green.500" fontWeight={"bold"}>
              ₹{maxFare}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
});
